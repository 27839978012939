var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "paddingT10 paddingB10 paddingLR20" },
    [
      _c("graph-title", { attrs: { title: _vm.title } }),
      _c("div", {
        staticStyle: { width: "100%", height: "290px" },
        attrs: { id: _vm.echartsId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }