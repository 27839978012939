<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper">
        <el-form
          :inline="true"
          label-position="right"
          ref="searchWrapper"
          :model="formInline"
          class="form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Statistical_time')" prop="age">
                <el-date-picker
                  v-model="time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  :clearable="false"
                  @change="sendDateChange"
                ></el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="getData()"
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <div class="overview marginT20">
        <viewEcharts
          style="width: 19%; height: 112px"
          v-for="item in viewCols"
          :key="item.echartsId"
          :color="item.color"
          :background="item.background"
          :unit="item.unit"
          :title="item.title"
          :type="item.type"
          :echartsId="item.echartsId"
          :Num="item.Num"
          :ref="item.echartsId"
        />
      </div>
      <div class="middle marginT20">
        <el-row :gutter="20">
          <el-col :span="8">
            <complainFrom
              echartsId="complainFrom"
              class="graphShadow"
              ref="complainFrom"
              title="投诉订单来源占比"
            ></complainFrom>
          </el-col>
          <el-col :span="16">
            <lineCahrts echartsId="lineCharts" class="graphShadow" ref="lineCharts"></lineCahrts>
          </el-col>
        </el-row>
      </div>
      <div class="bottom marginT20">
        <el-row :gutter="20">
          <el-col :span="8">
            <complainFrom
              echartsId="satisfied"
              class="graphShadow"
              ref="satisfied"
              title="用户满意度占比"
            ></complainFrom>
          </el-col>
          <el-col :span="16">
            <barCharts echartsId="barCharts" class="graphShadow" ref="barCharts"></barCharts>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import viewEcharts from "./echarts/overviewEcharts";
import complainFrom from "./echarts/complainFrom";
import lineCahrts from "./echarts/lineCahrts";
import barCharts from "./echarts/barCharts";
import { dateFormat } from "@/common/js/public";
export default {
  components: {
    viewEcharts,
    complainFrom,
    lineCahrts,
    barCharts,
  },
  data() {
    let today = dateFormat(new Date(new Date().getTime() - 86400000), "yyyy-MM-dd");
    let yesterday = dateFormat(new Date(new Date().getTime() - 86400000 * 7), "yyyy-MM-dd");
    return {
      timeFalg: true,
      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= new Date().setHours(0, 0, 0, 0);
        },
      },
      time: [yesterday, today],
      formInline: {
        startDate: yesterday,
        endDate: today,
      },
      viewCols: [
        {
          color: "#3F9EFF",
          background: "rgba(63, 158, 255, 0.15)",
          type: "",
          unit: "条",
          title: "投诉总量",
          echartsId: "",
          Num: 0,
        },
        {
          color: "#F6511D",
          background: "rgba(255, 93, 137, 0.15)",
          type: "space-between",
          unit: "条",
          title: "待处理数量",
          echartsId: "viewEcharts2",
          Num: 0,
          NumAll: 0,
        },
        {
          color: "#FFBE3F",
          background: "rgba(255, 190, 63, 0.15)",
          type: "space-between",
          unit: "条",
          title: "跟进中数量",
          echartsId: "viewEcharts3",
          Num: 0,
          NumAll: 0,
        },
        {
          color: "#3F9EFF",
          background: "rgba(156, 200, 19, 0.15)",
          type: "space-between",
          unit: "条",
          title: "已处理数量",
          echartsId: "viewEcharts4",
          Num: 0,
          NumAll: 0,
        },
        {
          color: "#7F84E9",
          background: "rgba(195, 127, 233, 0.15)",
          type: "space-between",
          unit: "%",
          title: "按时处理比率",
          echartsId: "viewEcharts5",
          Num: 0,
          NumAll: 0,
        },
      ],
    };
  },
  methods: {
    sendDateChange() {
      let timeNum = new Date(this.time[1]).getTime() - new Date(this.time[0]).getTime();
      if (timeNum < 86400000 * 31) {
        this.formInline.startDate = dateFormat(this.time[0], "yyyy-MM-dd");
        this.formInline.endDate = dateFormat(this.time[1], "yyyy-MM-dd");
        this.timeFalg = true;
      } else {
        this.timeFalg = false;
      }
    },
    getData() {
      this.getViewData();
      this.getComplainFromData();
      this.getSatData();
      this.getQuestionData();
    },
    getViewData() {
      if (!this.timeFalg) {
        this.$alert("只能选择31天内的数据");
        return;
      }
      this.$axios
        .get("/acb/2.0/bigDataCount/disposestatus", {
          data: {
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res && res.value) {
            let dataAll = res.value.finishnum + res.value.undisposnum + res.value.workingnum;
            this.viewCols[0].Num = dataAll;
            this.viewCols[1].Num = res.value.undisposnum;
            this.viewCols[2].Num = res.value.workingnum;
            this.viewCols[3].Num = res.value.finishnum;
            this.viewCols[4].Num =
              dataAll != 0 ? ((res.value.intimenum / dataAll) * 100).toFixed(2) : 0;
            this.viewCols[1].NumAll = dataAll;
            this.viewCols[2].NumAll = dataAll;
            this.viewCols[3].NumAll = dataAll;
            this.viewCols[4].NumAll = 100;
            this.$nextTick(() => {
              this.viewCols.forEach((e) => {
                e.echartsId && this.$refs[e.echartsId][0].init(e.NumAll);
              });
            });
          } else {
            this.viewCols[0].Num = 0;
            this.viewCols[1].Num = 0;
            this.viewCols[2].Num = 0;
            this.viewCols[3].Num = 0;
            this.viewCols[4].Num = 0;
            this.viewCols[1].NumAll = 0;
            this.viewCols[2].NumAll = 0;
            this.viewCols[3].NumAll = 0;
            this.viewCols[4].NumAll = 0;
            this.$nextTick(() => {
              this.viewCols.forEach((e) => {
                e.echartsId && this.$refs[e.echartsId][0].init(e.NumAll);
              });
            });
          }
        });
    },
    getComplainFromData() {
      if (!this.timeFalg) {
        this.$alert("只能选择31天内的数据");
        return;
      }
      this.$axios
        .get("/acb/2.0/bigDataCount/compdatasourse", {
          data: {
            ...this.formInline,
          },
        })
        .then((res) => {
          // 投诉订单来源占比
          let satData = {
            ser: [],
            leg: [],
          };
          res.value.dataAll &&
            res.value.dataAll.forEach((e) => {
              satData.ser &&
                satData.ser.push({
                  value: e.complaintnum,
                  name: e.dataSourceName,
                });
              satData.leg && satData.leg.push(e.dataSourceName);
            });
          this.$refs.complainFrom.init(satData);

          // 投诉订单来源趋势
          let complainFromTime = [];
          let complainFromData = [];
          let complainFromLegend = res.value && res.value.dateSourceName;
          let color = ["#00A6ED", "#9CC813", "#FFBE3F", "#C37FE9"];
          res.value.dateSourceTrend &&
            res.value.dateSourceTrend[0].data.forEach((t) => {
              complainFromTime.push(t.dataDate);
            });
          res.value.dateSourceTrend &&
            res.value.dateSourceTrend.forEach((e, idx) => {
              let obj = {
                data: [],
              };
              obj.name = e.dataSourceName;
              obj.lineStyle = {
                color: color[idx],
              };
              obj.type = "line";
              obj.smooth = true;
              e.data.forEach((i) => {
                obj.data.push(i.complaintnum);
              });
              complainFromData.push(obj);
            });
          this.$refs.lineCharts.init(complainFromLegend, complainFromTime, complainFromData);
        });
    },
    getSatData() {
      if (!this.timeFalg) {
        this.$alert("只能选择31天内的数据");
        return;
      }
      this.$axios
        .get("/acb/2.0/bigDataCount/compresult", {
          data: {
            ...this.formInline,
          },
        })
        .then((res) => {
          let satData = {
            ser: [],
            leg: [],
          };
          if (res.value && res.value.length) {
            res.value.forEach((e) => {
              satData.ser.push({
                value: e.complaintnum,
                name: e.dataSourceName,
              });
              satData.leg.push(e.dataSourceName);
            });
          } else {
            satData = {
              ser: [],
              leg: [],
            };
          }

          this.$refs.satisfied.init(satData);
        });
    },
    getQuestionData() {
      if (!this.timeFalg) {
        this.$alert("只能选择31天内的数据");
        return;
      }
      this.$axios
        .get(" acb/2.0/bigDataCount/compquestion", {
          data: {
            ...this.formInline,
          },
        })
        .then((res) => {
          let barChartsData = {
            x: [],
            y: [],
          };
          res.value &&
            res.value.forEach((e) => {
              barChartsData.x.push(e.dataSourceName);
              barChartsData.y.push(e.complaintnum);
            });
          this.$refs.barCharts.init(barChartsData);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="stylus" scoped>
.form-inline {
  padding-top 10px
}
.overview {
  display: flex;
  color: 0xf 0;
  justify-content: space-between;
  margin-bottom 20px
}

.middle {
  width: 100%;
}

.bottom {
  width: 100%;
}
</style>
