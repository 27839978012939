var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overviewEcharts" }, [
    _c("div", { staticClass: "top", style: { "justify-content": _vm.type } }, [
      _c("div", { staticClass: "firstBox", style: "just" }, [
        _c("div", { staticClass: "icon" }),
        _c("div", { staticClass: "NumBox", style: { color: _vm.color } }, [
          _vm._v(" " + _vm._s(_vm.Num) + " "),
        ]),
        _c("div", { staticClass: "unit" }, [
          _vm._v(" " + _vm._s(_vm.unit) + " "),
        ]),
      ]),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: this.type,
            expression: "this.type",
          },
        ],
        staticStyle: { width: "60px", height: "60px" },
        attrs: { id: _vm.echartsId },
      }),
    ]),
    _c(
      "div",
      { staticClass: "bottom", style: { background: _vm.background } },
      [_vm._v(" " + _vm._s(_vm.title) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }