<template>
  <div class="overviewEcharts">
    <div class="top"  :style="{'justify-content': type}">
      <div class="firstBox" :style="'just'">
        <div class="icon"></div>
        <div class="NumBox" :style="{'color': color}">
          {{Num}}
        </div>
        <div class="unit">
          {{unit}}
        </div>
      </div>
      <div :id="echartsId" style="width: 60px; height: 60px" v-show="this.type"></div>
    </div>
    <div class="bottom" :style="{'background': background}">
      {{title}}
    </div>
  </div>
</template>
<script>
export default {
  props: ["color", "background", "unit", 'type', 'title', 'echartsId', 'Num'],
  data() {
    return {};
  },
  methods: {
    init(NumAll) {
      let myCharts = this.$echarts.init(document.getElementById(this.echartsId));
      myCharts.clear()
      let option = {
        animation: false,
        series: [
          {
            name: "访问来源",
            color: ['#dddddd', this.color],
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              { value: NumAll - this.Num, name: "直接访问" },
              { value: this.Num, name: "邮件营销" }
            ]
          }
        ]
      }
      myCharts.setOption(option);
    }
  }
};
</script>
<style lang="stylus" scoped>
  .overviewEcharts {
    width: 100%;
    height 100%;
    padding 0 10px 10px 10px;
    display inline-block;
    box-shadow: 0px 0px 10px #888888;
    box-sizing border-box;
    .top {
      width 100%;
      height 70%;
      padding 0 20px;
      box-sizing border-box;
      display: flex;
      align-items center;
      justify-content center;
      .firstBox {
        display: flex;
        align-items center;
        justify-content center;
        .icon {
          background url('../../../../assets/img/phone.png') no-repeat 100% 100% / 100% 100%
          width 20px;
          height 20px;
        }
        .NumBox {
          margin-left 4px;
          font-size 32px;
          line-height 32px;
          font-weight 600;
        }
        .unit {
          margin-left 2px;
          font-size 12px;
          margin-top 10px;
        }
      }
    }
    .bottom {
      width 100%;
      height 30%;
      text-align center;
      line-height 33px;
      color rgba(0, 0, 0, 0.45)
      font-weight 600
    }
    #viewEcharts {
      width 60px;
      height 60px;
    }
  }
</style>
