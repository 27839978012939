<!--
 * @Author: your name
 * @Date: 2020-10-26 15:19:00
 * @LastEditTime: 2020-10-27 15:40:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \trunk\src\pages\dataSta\echarts\complainFrom.vue
-->
<template>
  <div class="paddingT10 paddingB10 paddingLR20">
    <graph-title :title="title" ></graph-title>
    <div :id="echartsId" style="width: 100%;height: 290px;"></div>
  </div>
</template>

<script>
import GraphTitle from '../../../../components/graphTitle';

export default {
  props: ["echartsId", 'title'],
  data() {
    return {};
  },
  components: {
    GraphTitle,
  },
  watch: {
    echartsId: {
      handler(val) {
        this.init(val)
      },
      deep: true
    }
  },
  methods: {
    init(chartData) {
      let myCharts = this.$echarts.init(
        document.getElementById(this.echartsId)
      );
      myCharts.clear()
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} <br/>{a}: {d}% "
        },
        legend: {
          orient: "horizontal",
          x: "center",
          bottom: "0px",
          selectedMode: false,
          data: chartData.leg,
          formatter: function(name) {
            return `${name}`
          }
        },
        series: [
          {
            name: "占比",
            color: ["#3F9EFF", " #9CC813", "#F6511C", "#FFBE3F"],
            type: "pie",
            radius: ["40%", "55%"],
            label: {
              show: false
            },
            avoidLabelOverlap: false,
            labelLine: {
              normal: {
                show: false
              }
            },
            data: chartData.ser
          }
        ]
      };
      myCharts.setOption(option);
    }
  }
};
</script>
