<!--
 * @Author: your name
 * @Date: 2020-10-26 15:19:00
 * @LastEditTime: 2020-10-27 15:40:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \trunk\src\pages\dataSta\echarts\barCharts.vue
-->
<template>
  <div class="paddingT10 paddingB10 paddingLR20">
    <graph-title title="投诉订单来源趋势" ></graph-title>
    <div :id="echartsId" style="width: 100%%; height: 290px;"></div>
  </div>
</template>

<script>
import GraphTitle from '../../../../components/graphTitle';
export default {
  props: ["echartsId", "chartData"],
  data() {
    return {};
  },
  components: {
    GraphTitle,
  },
  methods: {
    init(chartData) {
      let myCharts = this.$echarts.init(
        document.getElementById(this.echartsId)
      );
      myCharts.clear()
      let option = {
        title: {
          text: '问题类型统计',
          textStyle: {
            color: '#4C6072',
            fontSize: 16
          },
          padding: [10, 0, 0, 10]
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "6%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            data: chartData.x,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: "value"
          }
        ],
        series: [
          {
            name: "数量",
            type: "bar",
            data: chartData.y,
            barWidth: 30,
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = ["#3F9EFF", '#434348', '#7FB800', '#FFBE3F', '#7F84E9', '#7F84E9'];
                  return colorList[params.dataIndex];
                }
              }
            }
          }
        ]
      };
      myCharts.setOption(option);
    }
  }
};
</script>
