// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/img/phone.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".overviewEcharts[data-v-ab2ab6b6] {\n  width: 100%;\n  height: 100%;\n  padding: 0 10px 10px 10px;\n  display: inline-block;\n  -webkit-box-shadow: 0px 0px 10px #888;\n          box-shadow: 0px 0px 10px #888;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n}\n.overviewEcharts .top[data-v-ab2ab6b6] {\n  width: 100%;\n  height: 70%;\n  padding: 0 20px;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n.overviewEcharts .top .firstBox[data-v-ab2ab6b6] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n.overviewEcharts .top .firstBox .icon[data-v-ab2ab6b6] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 100% 100%/100% 100%;\n  width: 20px;\n  height: 20px;\n}\n.overviewEcharts .top .firstBox .NumBox[data-v-ab2ab6b6] {\n  margin-left: 4px;\n  font-size: 32px;\n  line-height: 32px;\n  font-weight: 600;\n}\n.overviewEcharts .top .firstBox .unit[data-v-ab2ab6b6] {\n  margin-left: 2px;\n  font-size: 12px;\n  margin-top: 10px;\n}\n.overviewEcharts .bottom[data-v-ab2ab6b6] {\n  width: 100%;\n  height: 30%;\n  text-align: center;\n  line-height: 33px;\n  color: rgba(0,0,0,0.45);\n  font-weight: 600;\n}\n.overviewEcharts #viewEcharts[data-v-ab2ab6b6] {\n  width: 60px;\n  height: 60px;\n}\n", ""]);
// Exports
module.exports = exports;
