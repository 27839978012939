<!--
 * @Author: your name
 * @Date: 2020-10-26 15:19:00
 * @LastEditTime: 2020-10-27 15:39:19
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \trunk\src\pages\dataSta\echarts\lineCahrts.vue
-->
<template>
  <div class="paddingT10 paddingB10 paddingLR20">
    <graph-title title="投诉订单来源趋势" ></graph-title>
    <div :id="echartsId" style="width: 100%; height: 290px;"></div>
  </div>
</template>

<script>
import GraphTitle from '../../../../components/graphTitle';
export default {
  props: ["echartsId"],
  data() {
    return {};
  },
  components: {
    GraphTitle,
  },
  methods: {
    init(legend, complainFromTime, complainFromData) {
      let myCharts = this.$echarts.init(
        document.getElementById(this.echartsId)
      );
      myCharts.clear();
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985"
            }
          }
        },
        color: ["#00A6ED", "#9CC813", "#FFBE3F", "#C37FE9"],
        xAxis: {
          type: "category",
          data: complainFromTime
        },
        yAxis: {
          type: "value"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "7%",
          containLabel: true
        },
        legend: {
          data: legend,
          show: true,
          orient: "horizontal",
          x: "center",
          bottom: "0px",
          selectedMode: false
        },
        series: complainFromData
      };
      myCharts.setOption(option);
    }
  }
};
</script>
